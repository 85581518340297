// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-austria-ewm-tsx": () => import("./../../../src/pages/austria/ewm.tsx" /* webpackChunkName: "component---src-pages-austria-ewm-tsx" */),
  "component---src-pages-austria-gem-tsx": () => import("./../../../src/pages/austria/gem.tsx" /* webpackChunkName: "component---src-pages-austria-gem-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cwm-tsx": () => import("./../../../src/pages/cwm.tsx" /* webpackChunkName: "component---src-pages-cwm-tsx" */),
  "component---src-pages-data-protection-regulation-tsx": () => import("./../../../src/pages/data-protection-regulation.tsx" /* webpackChunkName: "component---src-pages-data-protection-regulation-tsx" */),
  "component---src-pages-europe-ewm-tsx": () => import("./../../../src/pages/europe/ewm.tsx" /* webpackChunkName: "component---src-pages-europe-ewm-tsx" */),
  "component---src-pages-europe-gem-tsx": () => import("./../../../src/pages/europe/gem.tsx" /* webpackChunkName: "component---src-pages-europe-gem-tsx" */),
  "component---src-pages-ewm-tsx": () => import("./../../../src/pages/ewm.tsx" /* webpackChunkName: "component---src-pages-ewm-tsx" */),
  "component---src-pages-gem-tsx": () => import("./../../../src/pages/gem.tsx" /* webpackChunkName: "component---src-pages-gem-tsx" */),
  "component---src-pages-global-cwm-tsx": () => import("./../../../src/pages/global/cwm.tsx" /* webpackChunkName: "component---src-pages-global-cwm-tsx" */),
  "component---src-pages-global-gem-tsx": () => import("./../../../src/pages/global/gem.tsx" /* webpackChunkName: "component---src-pages-global-gem-tsx" */),
  "component---src-pages-imprint-tsx": () => import("./../../../src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

